define('Magento_Catalog/js/price-updator-pdp',[
    'jquery',
    'mage/validation',
    'mage/translate'
    ],
    function($){
        return function(params) {
        var updatePrice = function(priceblock, viewsandorders) {
            if (
                (priceblock.indexOf("isCallForPriceYes") > 0) ||
                (priceblock.indexOf("require_register") > 0)
            ) {
                $('#product_info_id').css('display','none');
                $('#product_info_id_sticky').css('display','none');
                $('.view-stock-global-pdp').addClass('hidden');
            } else {
                $('#product_info_id').css('display','block');
                $('#product_info_id_sticky').css('display','block');
                $('.view-stock-global-pdp').removeClass('hidden');
                $('.view-stock-global-pdp').addClass('show');
            }

            $('#product-addtocart-pickup').attr('data-price',priceblock.replace(/hidden/g, ""));
            $('.product-item-prototypes .price-box.price-final_price').replaceWith(priceblock);
            $('.detail-wrap .price-box.price-final_price').replaceWith(priceblock);
            $('.pdp-sticky-head .price-box.price-final_price').replaceWith(priceblock);


            $('.lastorder').html(viewsandorders);
            $('#product_addtocart_form').find('.price-box.price-final_price').addClass(' visible-xs');
            $('.price-block-wrapper').removeClass(' hidden');
        };

        var checkExpiry = function(timestamp) {
            var lifetime = 60;
            if(params.cacheLifetime) { lifetime = params.cacheLifetime; }
            var currentTime = Date.now();
            var expiry = timestamp + ( lifetime * 60 * 1000 );
            return currentTime < expiry;
        }

        var priceLocal = JSON.parse(window.getLocalStorage('pdpPriceData', true)) || { userIdentifier: 'guest' };
        $.ajax({
            type: 'POST',
            url: params.baseurl + 'priceupdate',
            data:{
                productId : params.productid,
                userIdentifier : priceLocal.userIdentifier,
                cached : priceLocal.hasOwnProperty(params.sku) && checkExpiry(priceLocal[params.sku].timestamp),
                canShowPrice: priceLocal.hasOwnProperty('canShowPrice') ? priceLocal.canShowPrice : null
            },
            success:function(data){
                var object = JSON.parse(data);
                if (object.userIdentifier !== priceLocal.userIdentifier || !priceLocal.hasOwnProperty('canShowPrice') || priceLocal.canShowPrice !== object.canShowPrice) {
                    priceLocal = {
                        userIdentifier: object.userIdentifier,
                        canShowPrice: object.canShowPrice
                    };
                }
                if (object.priceblock) {
                    priceLocal[params.sku] = {
                        priceblock: object.priceblock,
                        timestamp: Date.now()
                    }
                }
                updatePrice(priceLocal[params.sku].priceblock, object.viewsandorders);
                window.setLocalStorage('pdpPriceData', priceLocal, true)
            }
        });
    }
});

