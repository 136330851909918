define('Usesi_CommerceConnector/js/set-cookie',[
    'jquery',
    'mage/cookies'
], function($) {
    return function(values) {
        var getParams = function (url) {
            var params = {};
            var parser = document.createElement('a');
            parser.href = url;
            var query = parser.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
            return params;
        };
        var params = getParams(window.location.href);
        if (params.hasOwnProperty(values.urlFlag)) {
            var expiry = new Date();
            expiry.setMinutes(expiry.getMinutes() + values.duration);
            $.cookie(values.cookieName, true, {path: '/', expires: expiry});
        }
    }
});
