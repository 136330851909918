define('Magento_Catalog/js/pd-updator-pdp',[
'jquery', 
'mage/template',
'text!Usesi_PickupDeliveryData/templates/pickup_delivery.html',
'text!Usesi_PickupDeliveryData/templates/product/view/pickup_delivery.html',
'mage/validation',
'mage/translate'
],
function($, mageTemplate, pickupDeliveryStickyTmpl, pickupDeliveryTmpl){
    return function(params) {
        var branchName = $.cookie('usesiBranch');
        var updateInv = function(invData) {
            if(invData.pdpInvData.is_discontinued == 1) {
                $('#product-addtocart-button,#product-addtocart-pickup').attr('disabled', 'disabled');
                $('.pickup-delivery-data,.box-tocart').addClass('hidden');
                $('#product-addtocart-button span').html($('#product-addtocart-button').attr('data-title'));
                $('.dec.button,.inc.button').addClass('disabled');
                $('.price-final_price,.price-box').addClass('hidden');
                $('.pdp-discontinued').removeClass('hidden');
            } else if (invData.pdpInvData.is_discontinued_label == 1) {
                $('.pdp-discontinued').removeClass('hidden');
            }

            if (!params.canShowInventory) {
                return;
            }

            var pickup_deliver_info = $('.pickup-deleiver-info');
            var pickup_delivery_inventory = $('.pickup-delivery-inventory');
            var delivery_info = $('.product-deliver-stock');
            var pick_info = $('.product-pickup-stock');
            var call_toggle_flag=0;
            if(invData.pdpInvData.pickup_available_status.indexOf("Out")>-1){
                call_toggle_flag=1;
            }
            /** Qty Change- Sticky Inventory */
            $(mageTemplate(pickupDeliveryStickyTmpl, {
                pddata: {
                    pickup: invData.pdpInvData.pickup,
                    call_toggle:call_toggle_flag,
                    pickup_available_status: invData.pdpInvData.pickup_available_status,
                    deliver:invData.pdpInvData.deliver,
                    deliverarea:invData.pdpInvData.deliverarea,
                    delivery_available_status:invData.pdpInvData.delivery_available_status
                }
            })).appendTo(pickup_deliver_info);
            try {
                var url = new URL(window.location.href);
                if (url.searchParams.get("isPickup") == 1) {
                    $('#pickup_radio_option').click();
                }
            } catch (e) {}
            /** Qty Change - Delivery */
            delivery_info.html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'delivery',
                    deliver:invData.pdpInvData.deliver,
                    delivery_available_status:invData.pdpInvData.delivery_available_status
                }
            }));
            /** Qty Change - Pickup */
            pick_info.html(mageTemplate(pickupDeliveryTmpl, {
                data: {
                    type: 'pickup',
                    pickup: invData.pdpInvData.pickup,
                    pickup_available_status: invData.pdpInvData.pickup_available_status
                }
            }));
            $('#stock_status').val(invData.pdpInvData.delivery_available_status);
            pickup_deliver_info.trigger('contentUpdated');
            pickup_delivery_inventory.trigger('contentUpdated');
        };
        $('body').on('click','.pdradio', function(){
            $('.radio-option-pd-wrapper').removeClass('choosed');
            $(this).closest('div').addClass('choosed');
        });
        var checkExpiry = function(timestamp) {
            var lifetime = 60;
            if(params.cacheLifetime) { lifetime = params.cacheLifetime; }
            var currentTime = Date.now();
            var expiry = timestamp + ( lifetime * 60 * 1000 );
            return currentTime < expiry;
        };
        var invLocal = null;
        try {
            invLocal = JSON.parse(window.getLocalStorage('pdpInvData', true));
        } catch (e) {}
        if (invLocal == null) {
            invLocal = {};
        }

        var ownBranchObj = invLocal[branchName] || {};
        if(invLocal.hasOwnProperty(branchName) && ownBranchObj.hasOwnProperty(params.sku) && checkExpiry(invLocal[branchName][params.sku].timestamp)) {
            var product = invLocal[branchName][params.sku];
            updateInv(product.invData);
        } else {
            $.ajax({
                type:'POST',
                url: params.baseurl + 'pddata/index/update',
                data:{
                    'productid' : params.productid,
                    'uom' : params.uom
                },
                success:function(data){
                    var sku ;
                    updateInv(data);
                    sku = params.sku;
                    invLocal[branchName] =  invLocal[branchName] || {};
                    invLocal[branchName][sku] = {invData: data, timestamp: Date.now()};
                    window.setLocalStorage('pdpInvData', invLocal, true);
                }
            });
        }
    };
});

